import { useRef, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { TagType } from "shared-module";
import { TagModel } from "../../../models/TagModel";
import { TagService } from "../../portfolio/services/tag.service";
import { ListResult } from "../types/ListResult";
import authContext from "../../auth/context/AuthContext";

export function useCachedTagListQuery(tagType: TagType, enabled = true) {
    // @ts-ignore
    const { i18n } = useTranslation();
    const service = useRef(new TagService());
    const queryClient = useQueryClient();
    const { user } = useContext(authContext);

    const queryKey = useMemo(() => {
        return ['tags', tagType, i18n.language] as const;
    }, [tagType, i18n.language]);

    return useQuery<
        ListResult<TagModel>,
        unknown,
        ListResult<TagModel>,
        typeof queryKey
    >({
        queryKey: queryKey,
        queryFn: (ctx) => service.current.getListByType({ type: ctx.queryKey[1] }),
        initialData: () => queryClient.getQueryData<ListResult<TagModel>>(queryKey),
        initialDataUpdatedAt: () => queryClient.getQueryState(queryKey)?.dataUpdatedAt,
        staleTime: 15 * 60 * 1000, //(15 minutes)
        enabled: enabled && !!user,
        onSuccess: (data) => {
            const sortData = (data: ListResult<TagModel>, order: string[]) => {
                const copy = [...data.items];
                copy.sort((a, b) => order.indexOf(a.pk) - order.indexOf(b.pk));
                data.items = copy;
                queryClient.setQueryData(queryKey, data);
            };

            if (tagType === 'classLevels') {
                sortData(data, [
                    'classLevels#072c52d9-f42c-473f-bbc9-b1dd80cfcf97', // All levels
                    'classLevels#9af25d6e-dbb7-4b4c-bccc-a2e2ca0c51b8', // beginner
                    'classLevels#32d2a1d1-097b-4526-8e99-d6541ba9ebb7', // intermediate
                    'classLevels#82d6906f-a32c-4543-9305-8def5b22cfb7', // advanced
                    'classLevels#221b1d41-1108-4046-beaa-2aa4a94def32', // pre-professional
                    'classLevels#5daf085f-437e-4e74-b0fb-861d6d0d4382' // professional
                ]);
            }

            if (tagType === 'jobTypes') {
                sortData(data, [
                    'jobTypes#1037de24-0d59-4549-a169-7843ffd08b02', // Full-time
                    'jobTypes#99ec72c9-29fe-4120-a9db-529576d300b9', // Part-time
                    'jobTypes#11824576-6e7e-418d-b8a5-28a05f8c890b', // Contractual
                    'jobTypes#f95a2cf1-219c-4d0e-b288-ffc16bcd1bf4', // Freelance
                ]);
            }

            if (tagType === 'artisticCategories') {
                sortData(data, [
                    'artisticCategories#9b990b92-b91e-4892-a5b6-5f172f8ea576', // aspring
                    'artisticCategories#ef87fac9-832f-4940-b0b4-1b51b64a11d3', // amateur
                    'artisticCategories#5d9b4548-85f8-4162-8368-a81c2d5857bc', // emerging
                    'artisticCategories#53fb9891-bd2b-45a3-b44c-229ba71caa6a', // pro
                ]);
            }
        }
    });
}