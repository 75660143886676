import { useEffect, useRef } from "react";

export default function (show: boolean, onHidden?: () => void, onCloseRequest?: () => boolean, modalId: string = '') {
    const ref = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (ref.current && modalId === ref.current.id && show) {
            ref.current.showModal();
            document.body.style.overflow = 'hidden';
        } else if (ref.current && modalId === ref.current.id && !show) {
            ref.current.close();
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [show]);

    useEffect(() => {
        if (ref.current && modalId === ref.current.id) {
            const element = ref.current;
            const closeHandler = () => onHidden?.();
            element.addEventListener('close', closeHandler);

            return () => {
                element.removeEventListener('close', closeHandler);
            }
        }
    }, [onHidden]);

    useEffect(() => {
        if (ref.current && modalId === ref.current.id) {
            const element = ref.current;
            const cancelHandler = (e: Event) => {
                if (onCloseRequest && !onCloseRequest()) {
                    e.preventDefault();
                }
            };

            element.addEventListener('cancel', cancelHandler);

            return () => {
                element.removeEventListener('cancel', cancelHandler);
            }
        }
    }, [onCloseRequest]);

    return {
        divRef: ref,
    }
}