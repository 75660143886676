import React, { useEffect, useState } from 'react';
import { createContext, PropsWithChildren } from "react";
import DeletePromptModal from '../../components/modal/DeletePromptModal';

export const DeletePromptModalContext = createContext({
    show: (onConfirm: () => void) => { },
    close: () => { },
    setTitleKey: (key: string) => { }
});

export const DeletePromptModalProvider = ({ children }: PropsWithChildren<unknown>) => {
    const [show, setShow] = useState(false);
    const [onConfirm, setOnConfirm] = useState<() => void>(() => { });
    const [titleKey, setTitleKey] = useState<string>('deleteThis');

    const handleShow = (onConfirm: () => void) => {
        setOnConfirm(() => onConfirm);
        setShow(true);
    }

    const handleCancel = () => {
        setOnConfirm(() => { });
        setShow(false);
    }

    const handleConfirm = () => {
        onConfirm();
        setShow(false);
    }

    const handleClose = () => {
        setTitleKey('deleteThis');
    }

    useEffect(() => {
        if (!show) {
            handleClose();
        }
    }, [show]);

    return (
        <DeletePromptModalContext.Provider value={{
            show: handleShow,
            close: () => setShow(false),
            setTitleKey: (key: string) => setTitleKey(key)
        }}>
            {children}

            {show &&
                <DeletePromptModal
                    show={show}
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                    titleKey={titleKey || 'deleteThis'}
                />
            }
        </DeletePromptModalContext.Provider>
    )
}