import React, { Suspense, useContext, useRef } from 'react'
import authContext from '../../auth/context/AuthContext'
import { PageTitleProvider } from '../contexts/pageTitleContext/PageTitleContext'
import styled from 'styled-components'
import { PortfolioAppContext } from '../../portfolio/context/PortfolioAppContext'
import { PortfolioListProvider } from '../../portfolio/portfolioList/contexts/PortfolioListContext'
import { PortfolioService } from '../../portfolio/services/portfolio.service'
import EventListProvider from '../../portfolio/events/contexts/EventListContext'
import AnnouncementListProvider from '../../portfolio/announcement/contexts/AnnouncementListContext'

const PaywallModal = React.lazy(() => import('../../subscription/components/PaywallModal/PaywallModal'));
const Navigation = React.lazy(() => import('../../../Navigation'));
const NavBar = React.lazy(() => import('./NavBar'));
const Spinner = React.lazy(() => import('../components/Spinner'));

export default function Layout() {
    const { isLoading } = useContext(authContext);
    const { user } = useContext(authContext);
    const portfolioService = useRef(new PortfolioService());

    return (
        <PageTitleProvider>
            <Suspense fallback={<Spinner isInMiddleScreen />}>
                <PortfolioAppContext.Provider value={{
                    portfolioService: portfolioService.current!,
                }}>
                    <PortfolioListProvider>
                        <EventListProvider>
                            <AnnouncementListProvider>
                                {!isLoading &&
                                    <StyledMain>
                                        <NavBar />
                                        <Navigation />
                                    </StyledMain>
                                }

                                {user && <PaywallModal />}
                            </AnnouncementListProvider>
                        </EventListProvider>
                    </PortfolioListProvider>
                </PortfolioAppContext.Provider>
            </Suspense>
        </PageTitleProvider>
    );
}

const StyledMain = styled.main({
    height: '100vh',
});