import React, { useContext } from 'react'
import { DeletePromptModalContext } from '../../contexts/modalContext/DeletePromptModalContext'
import Modal from './Modal'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledButton } from '../../../portfolio/onboarding/StyledButton';

export const FlexJustifyCenter = styled.div({
    display: 'flex',
    gap: '1rem',
    width: '100%',
    justifyContent: 'center',
});

export const FilledButton = styled(StyledButton)(() => ({
    backgroundColor: 'var(--db-green) !important',
    color: 'var(--db-white)',
    border: 'none !important',
}));

const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    justifyContent: 'center',

    span: {
        textAlign: 'center',
    },
});

type Props = {
    show: boolean,
    onConfirm: () => void,
    onCancel: () => void,
    titleKey?: string,
}

export default function DeletePromptModal({ show, onConfirm, onCancel, titleKey = 'deleteThis' }: Props) {
    // @ts-ignore
    const { t } = useTranslation('common');
    const { close } = useContext(DeletePromptModalContext);

    return (
        <Modal
            modalName='delete-prompt-modal'
            key="delete-prompt-modal-element"
            show={show}
            closable
            onHidden={close}
            centeredVertically
        >
            <Container>
                <span>{t(titleKey as any)}</span>

                <FlexJustifyCenter>
                    <FilledButton onClick={onConfirm}>{t('yes')}</FilledButton>
                    <StyledButton onClick={onCancel}>{t('no')}</StyledButton>
                </FlexJustifyCenter>
            </Container>
        </Modal>
    )
}